<template>
  <div>
    <Carousel
      :elements="items"
      :elements-per-page="1.3"
      :elements-per-page-desktop="3"
      :end-padding="true"
      :end-padding-desktop="true"
      :centered="useScreen().isMobile.value"
      :gap="5"
      :drag="useScreen().isMobile.value"
      class="mb-5"
      :skeleton-nb-elements="3"
      :skeleton-centered="true"
      @change="onChange">
      <template #slide="{ element, active }: { element: any, active: boolean }">
        <NuxtLink
          :to="
            localePath({
              name: 'radio-program-episode',
              params: {
                radio: element.program.radio.slug,
                program: element.program.id,
                episode: element.id
              }
            })
          "
          class="h-full">
          <div
            :class="[
              'transform duration-200',
              {
                'scale-90': useScreen().isMobile.value && !active,
                'scale-[0.95]': useScreen().isDesktop.value
              }
            ]">
            <NuxtImg
              format="webp"
              sizes="sm:100vw md:400px"
              :src="element.image || useImagePlaceholder('square')"
              :provider="element.image && 'directus'"
              fit="cover"
              width="280"
              height="280"
              :alt="translateItem(element)?.title"
              class="w-full rounded-xl object-contain" />
          </div>

          <div class="hidden dt:block">
            <h3 class="text-center text-lg text-white">
              {{ translateItem(element?.program)?.title }}
            </h3>
            <div class="mb-5 text-center text-neutral-700">
              {{ translateItem(element)?.title }}
            </div>
          </div>
        </NuxtLink>

        <div class="hidden dt:block">
          <SquareButton
            icon="play-fill"
            class="mx-auto text-2xl"
            :aria-label="$t('PodcastCarousel.play')"
            @click="$player.loadEpisode(element.id)" />
        </div>
      </template>
    </Carousel>

    <div
      v-if="currentItem"
      class="dt:hidden">
      <h3 class="text-center text-lg text-white">
        {{ translateItem(currentItem?.program)?.title }}
      </h3>
      <div class="mb-4 text-center text-neutral-700">
        {{ translateItem(currentItem)?.title }}
      </div>
      <SquareButton
        icon="play-fill"
        class="mx-auto text-2xl"
        :aria-label="$t('PodcastCarousel.play')"
        @click="$player.loadEpisode(currentItem.id)" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { translateItem } from '@/composables/use-translations'

const localePath = useLocalePath()

const props = defineProps<{
  items: {
    id: number
    image: Image
    translations: Array<Translation>
  }[]
}>()

const currentIndex = ref(0)

const currentItem = computed(() => props.items[currentIndex.value])

const onChange = (index: number) => {
  currentIndex.value = index
}
</script>
